import React, { FC } from 'react';
import {Autocomplete, IconButton, styled, TextField} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {ammoConfig, ammoOptions, detonatorOptions, initiatorOptions} from "../const";
import {AmmoForm} from "../types";

interface AmmoInputProps {
  index: number;
  isLast: boolean;
  addAmmo: () => void;
  removeAmmo: () => void;
  setFieldValue: (key: string, value: any) => void;
  ammo: AmmoForm;
  handleChange: any;
  touched: any;
  errors: any;
}

const AmmoInput: FC<AmmoInputProps> = ({
  index,
  isLast,
  addAmmo,
  removeAmmo,
  ammo,
  setFieldValue,
  handleChange,
  touched,
  errors,
                   }) => {

  const isFirst = index === 0;

  const handleChangeAmmo = (value: any) => {
    if(value){
      const config = ammoConfig[value];
      if(config){
       setFieldValue('initiator', config.initiator);
       setFieldValue('detonator',config.detonator);
      }else{
        setFieldValue('initiator', '');
        setFieldValue('detonator', '')
      }
    }
    setFieldValue('ammoType', value || '');
  };

  return <div>
    <Header>
      <Label>Боєприпас {!(isFirst && isLast) && index+1}</Label>
      {!isFirst ? <IconButton
        size="large"
        color="inherit"
        onClick={removeAmmo}
      >
        <RemoveIcon />
      </IconButton> : <BtnPlaceholder />}
      {isLast ? <IconButton
        size="large"
        color="inherit"
        onClick={addAmmo}
      >
        <AddIcon />
      </IconButton> : <BtnPlaceholder />}
    </Header>
    <SelectWrapper>
        <StyledAutocomplete
          disableClearable
            value={ammo.ammoType}
            onChange={(e, value) => handleChangeAmmo(value)}
            options={ammoOptions}
            renderInput={(params) => <StyledTextField
              {...params}
              name={`ammos[${index}.ammoType`}
              label="Боєприпас"
              error={touched?.ammoType && Boolean(errors?.ammoType)}
              helperText={touched?.ammoType && errors?.ammoType}
            />}
        />
        <QuantityInput
            label="К-сть"
            name={`ammos[${index}.ammoQuantity`}
            value={ammo.ammoQuantity}
            onChange={handleChange}
            type="number"
            error={touched?.ammoQuantity && Boolean(errors?.ammoQuantity)}
            helperText={touched?.ammoQuantity && errors?.ammoQuantity}
        />
    </SelectWrapper>
    <SelectWrapper>
        <StyledAutocomplete
          disableClearable
            value={ammo.detonator}
            onChange={(e, value) => setFieldValue('detonator', value || '')}
            options={detonatorOptions}
            renderInput={(params) => <StyledTextField
              {...params}
              name={`ammos[${index}.detonator`}
              label="Детонатор"
              error={touched?.detonator && Boolean(errors?.detonator)}
              helperText={touched?.detonator && errors?.detonator}
            />}
        />
        <QuantityInput
            label="К-сть"
            name={`ammos[${index}.detonatorQuantity`}
            value={ammo.detonatorQuantity}
            onChange={handleChange}
            type="number"
            error={touched?.detonatorQuantity && Boolean(errors?.detonatorQuantity)}
            helperText={touched?.detonatorQuantity && errors?.detonatorQuantity}
        />
    </SelectWrapper>
   <SelectWrapper>
        <StyledAutocomplete
          disableClearable
            value={ammo.initiator}
            onChange={(e, value) => setFieldValue('initiator', value || [])}
            options={initiatorOptions}
            renderInput={(params) => <StyledTextField
              {...params}
              name={`ammos[${index}.initiator`}
              label="Ініціатор"
              error={touched?.initiator && Boolean(errors?.initiator)}
              helperText={touched?.initiator && errors?.initiator}
            />}
        />
        <QuantityInput
            label="К-сть"
            name={`ammos[${index}.initiatorQuantity`}
            value={ammo.initiatorQuantity}
            onChange={handleChange}
            type="number"
            error={touched?.initiatorQuantity && Boolean(errors?.initiatorQuantity)}
            helperText={touched?.initiatorQuantity && errors?.initiatorQuantity}
        />
    </SelectWrapper>
  </div>
};

export default AmmoInput;

const Header = styled('div')`
  display: flex;
  align-items: center;
`;
const Label = styled('span')`
  flex: 1;
`;
const BtnPlaceholder = styled('div')`
  width: 48px;
  height: 48px;
  min-width: 48px;
`;
const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
const SelectWrapper = styled('div')`
  display: flex;
`;
const StyledAutocomplete = styled(Autocomplete)`
  flex: 1;
`;
const QuantityInput = styled(StyledTextField)`
  max-width: 65px;
  margin-left: ${({ theme }) => theme.spacing(4)};
`;
