import {Config} from "../../types";
import {defaultInitialTargetValue} from "../../const";

export const defaultCrewValue = 'ПОХ';
export const crewOptions = [defaultCrewValue, 'Докер'];

export type CrewCompositionItem = string | { value: string, label: string};

type CrewCompositionOptions = {
  [x: string]: CrewCompositionItem[]
}
export const crewCompositionOptions: CrewCompositionOptions = {
  [defaultCrewValue]: ['Пан Бум', 'Толк', 'Фотограф', 'Купер', 'Панда', 'Альп', 'Маріо', 'Лєший', 'Бусік', 'Велес', 'Скай'],
  'Докер': ['Пан Бум', 'Толк', 'Фотограф', 'Купер', 'Панда', 'Альп', 'Маріо', 'Лєший', 'Бусік', 'Велес', 'Скай'],
};

export const configInitialValues: Config = {
  crew: defaultCrewValue,
  crewComposition: [],
  vehicleType: 'Vampire',
  takeOffLocation: 'Мала Токмачка',
  attackArea: 'околиці Малої Токмачки',
  frequencies: '2.4 Vampire',
  altitude: '80-150м',
  areaOfResponsibility: '118 ОМБр',
  initialTargetValue: defaultInitialTargetValue,
};